import {useMediaQuery} from "@vueuse/core";

export default defineNuxtPlugin((nuxtApp) => {
    let breakpoints = {
        mobile: {min: '2px', max: '829px'},
        tablette: {min: '830px', max: '1199px'}
    };

    return {
        provide: {
            isMobile: ref(
                process.server ? false : useMediaQuery('(min-width: '+ breakpoints['mobile'].min +') and (max-width: '+ breakpoints['mobile'].max +')')
            ),
            isTablette: ref(
                process.server ? false : useMediaQuery('(min-width: '+ breakpoints['tablette'].min +') and (max-width: '+ breakpoints['tablette'].max +')')
            ),
            isDesktop: ref(
                process.server ? true : useMediaQuery('(min-width: '+ breakpoints['tablette'].max + ')')
            )
        }
    }
});
